<template>
    <v-dialog v-model="dialog" scrollable  persistent :max-width="widthByStep" content-class="cobrar-servicos-dialog">
        <v-card v-if="dialog" min-height="400px">
            <v-card-title>
                <span class="headline">Serviços à cobrar</span>
            </v-card-title>
            <v-card-text >
                <!--Se não tiver ibge-->
                <template v-if="step === 1">
                    <div class="custom-container">
                        <div class="header">
                            <v-alert text outlined dense
                                     color="orange" icon="mdi-alert"
                                     class="caption mb-0">
                                Para a emissão das notas fiscais é necessário que a cidade do tomador esteja com o código IBGE.
                                Preencha corretamente os códigos abaixo para poder continuar.
                            </v-alert>

                            <v-simple-table fixed-header v-if="servicos">
                                <template v-slot:default>
                                    <thead>
                                    <tr>
                                        <th style="width: 100%">Município</th>
                                        <th>Cod. IBGE</th>
                                    </tr>
                                    </thead>
                                </template>
                            </v-simple-table>
                        </div>
                        <div class="body">
                            <v-form ref="form1" v-model="form1Valid" lazy-validation>
                                <v-simple-table class="custom-table">
                                    <template v-slot:default>
                                        <tbody>
                                        <tr v-for="(cidade, index) in cidadesSemIbge" :key="cidade.id">
                                            <td style="">
                                                <span class="body-2 font-weight-light ">{{cidade.nome}}/{{cidade.estado.sigla}}</span>
                                            </td>
                                            <td>
                                                <v-text-field v-model="cidade.codigoIbge"
                                                              class="resposta-input caption font-weight-light"
                                                              height="30" style="width: 75px" background-color="white"
                                                              v-mask="'#######'"
                                                              dense outlined hide-details required
                                                              :rules="[rules.required, rules.min]" />
                                            </td>
                                        </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-form>
                        </div>
                    </div>


                </template>
                <!--Se não tiver numero no endereço-->
                <template v-if="step === 2">
                    <div class="custom-container">
                        <div class="header">
                            <v-alert text outlined dense
                                     color="orange" icon="mdi-alert"
                                     class="caption mb-0">
                                Para a emissão das notas fiscais é necessário que o endereço das agências esteja completo.
                                Preencha corretamente os endereços abaixo para poder continuar.
                            </v-alert>

                            <v-simple-table fixed-header v-if="servicos" class="mr-4">
                                <template v-slot:default>
                                    <thead>
                                    <tr>
                                        <th>Agência</th>
                                        <th style="width: 282px">Endereço</th>
                                        <th style="width: 112px">Número</th>
                                        <th style="width: 112px">Bairro</th>
                                        <th style="width: 112px">Cep</th>
                                    </tr>
                                    </thead>
                                </template>
                            </v-simple-table>
                        </div>
                        <div class="body">
                            <v-form ref="form2" v-model="form2Valid" lazy-validation>
                                <v-simple-table class="custom-table">
                                    <template v-slot:default>
                                        <tbody>
                                        <tr v-for="(agencia, index) in agenciasSemNumero" :key="agencia.id">
                                            <td style="width: 100%">
                                                <span class="body-2 font-weight-light ">{{agencia.codigo}} - {{agencia.nome}}</span>
                                            </td>
                                            <td>
                                                <v-text-field v-model="agencia.endereco"
                                                              class="resposta-input caption font-weight-light"
                                                              height="30" style="width: 250px" background-color="white"
                                                              dense outlined hide-details required :rules="[rules.required]"/>
                                            </td>
                                            <td>
                                                <v-text-field v-model="agencia.numero"
                                                              class="resposta-input caption font-weight-light"
                                                              height="30" style="width: 80px" background-color="white"
                                                              dense outlined hide-details required :rules="[rules.required]"/>
                                            </td>
                                            <td>
                                                <v-text-field v-model="agencia.bairro"
                                                              class="resposta-input caption font-weight-light"
                                                              height="30" style="width: 80px" background-color="white"
                                                              dense outlined hide-details required :rules="[rules.required]"/>
                                            </td>
                                            <td>
                                                <v-text-field v-model="agencia.cep"
                                                              class="resposta-input caption font-weight-light"
                                                              height="30" style="width: 80px" background-color="white"
                                                              v-mask="['#####-###']"
                                                              dense outlined hide-details required :rules="[rules.required, rules.cep]"/>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-form>
                        </div>
                    </div>


                </template>
                <template v-if="step === 3">
                    <div class="custom-container">
                        <div class="body">
                            <span class="font-weight-medium">Os passos a seguir serão executados (em ordem) em segundo plano.</span>
                            <v-timeline dense clipped  class="my-4">
                                <v-timeline-item small color="orange">Emissão das notas fiscais dos serviços</v-timeline-item>
                                <v-timeline-item small color="orange">Envio dos laudos para o banco</v-timeline-item>
                                <v-timeline-item small color="orange">Envio da solicitação de cobrança por email</v-timeline-item>
                            </v-timeline>
                            <span class="font-weight-medium">Caso algum passo acima falhe você será notificado. (Ver imagem abaixo)</span>
                            <v-img class="mt-4" src="/img/notificacoes_area_print.png" width="300" />
                        </div>
                    </div>

                </template>
            </v-card-text>
            <v-card-actions>
                <div class="pa-3" style="width: 100%">
                    <v-btn text @click="closeDialog">Cancelar</v-btn>
                    <div class="float-right">
                        <v-btn v-if="step === 1"
                               color="primary" text depressed @click="atualizarCidades" >
                            Salvar e prosseguir
                        </v-btn>
                        <v-btn v-else-if="step === 2"
                               color="primary" text depressed @click="atualizarAgencias" >
                            Salvar e prosseguir
                        </v-btn>
                        <template v-else>
                            <v-btn  color="primary" text depressed @click="emitirLote(loteId)" >
                                Iniciar
                            </v-btn>
                        </template>
                    </div>

                </div>
            </v-card-actions>
            <v-overlay absolute :value="loading" color="white">
                <v-progress-circular :size="100" :width="7" color="deep-orange" indeterminate></v-progress-circular>
            </v-overlay>
        </v-card>
        <servico-dialog ref="servicoDialog" />
<!--        <nota-fiscal-error-dialog ref="notaFiscalErrorDialog" />-->
    </v-dialog>
    
</template>

<script>
    import gql from "graphql-tag";
    // @ts-ignore
    import ServicoDialog from "../servico/fiscalizacao/servico/ServicoDialog";
    // @ts-ignore
    //import NotaFiscalErrorDialog from "./NotaFiscalErrorDialog";
    import {NotaFiscalQueries} from "../../queries/NotaFiscalQueries";

    export default {
        name: "CobrarServicosDialog",
        components: {
            ServicoDialog
        },
        data() {
            return {
                dialog: false,
                loading: false,
                loteId: null,
                step: 1,
                form1Valid: true,
                form2Valid: true,
                cidadesSemIbge: [],
                agenciasSemNumero: [],
                rules: {
                    required: (v) => (!!v && !!v.trim()) || 'Campo Obrigatório.',
                    min: (v) => v && v.length >= 7 || 'Mínimo 8 caracteres',
                    cep: (v) => v && v.length === 9 || 'Cep Inválido',
                },
            }
        },
        computed:{
            widthByStep(){
                return '1000px'
            }
        },
        methods:{
            openDialog(loteId) {
                this.step = 1;
                this.dialog = true;
                this.servicos = [];
                this.cidadesSemIbge = [];
                this.agenciasSemNumero = [];

                if(loteId == null){
                    this.loading = true
                    this.getLoteNaoFinalizado().then((loteId)=>{
                        this.loteId = loteId;
                        this.verificarInconsistenciasNoLote(loteId)
                    })
                }else{
                    this.loteId = loteId;
                    this.verificarInconsistenciasNoLote(loteId)
                }
            },
            closeDialog() {
                this.dialog = false;
                this.servicos = null;
            },
            getLoteNaoFinalizado(){
                return new Promise(resolve => {
                    this.$apollo.query({
                        query: gql`query{
                        lote:lotesServicoNaoFinalizado{
                            id
                        }
                    }`,
                    }).then(result => {
                        resolve(result.data.lote.id)
                    });
                })
            },
            verificarInconsistenciasNoLote(loteId){
                return this.$apollo.query({
                    query: gql`query($loteId: Int!){
                        cidades:cidadesSemIbgePorLote(lote_id: $loteId){
                            id
                            nome
                            codigoIbge
                            estado{
                                sigla
                            }
                        }
                        agencias:agenciasSemNumeroPorLote(lote_id: $loteId){
                            id
                            nome
                            codigo
                            endereco
                            numero
                            bairro
                            cep
                        }
                    }`,
                    variables: {
                        loteId: loteId,
                    }
                }).then((result) => {
                    this.cidadesSemIbge = result.data.cidades
                    this.agenciasSemNumero = result.data.agencias
                }).finally(()=>{
                    if(this.cidadesSemIbge.length > 0){
                        this.step = 1;
                        this.loading = false;
                    }else if(this.agenciasSemNumero.length > 0){
                        this.step = 2;
                        this.loading = false;
                    }else{
                        this.loading = false;
                        this.step = 3;
                        //this.getServicosPorLote(loteId);
                    }

                })
            },
            getServicosDeLoteNaoFinalizado(){
                this.loading = true;
                return this.$apollo.query({
                    query: gql`query{
                        servicos:servicosDeLoteNaoFinalizado{
                            id
                            distanciaExcedida
                            valorTotalDistanciaExcedida
                            valorTotalServico
                            agencia{
                                id
                                nome
                                codigo
                                endereco
                                numero
                                bairro
                                cnpj
                                cep
                                cidade{
                                    nome
                                    codigoIbge
                                    estado{
                                        sigla
                                    }
                                }
                            }
                            fiscalizacoes{
                                id
                                distanciaPercorrida
                                financiamento{
                                    mutuario
                                    valor
                                }
                            }
                            notaFiscal{
                                id
                                dataEmissao
                                numero
                                valorServicos
                            }
                            loteServico{
                                id
                            }
                        }
                    }`,
                }).then((result) => {
                    this.loteId = result.data.servicos[0].loteServico.id
                    this.servicos = result.data.servicos;
                    //this.totalServicos = result.data.servicosPorLote.totalCount
                    this.loading = false;
                });
            },
            atualizarCidades(){
                if(!this.$refs.form1.validate()){
                    this.$dialog.warning({
                        title: 'Atenção', text: 'Preencha todos os códigos para poder continuar',
                        actions: {ok:'ok'}
                    })
                    return
                }
                this.loading = true;
                this.$apollo.mutate({
                    mutation: gql`mutation ($cidades:[CidadeInput]!){
                        atualizaCidades(cidades: $cidades){
                            id
                        }
                    }`,
                    variables: {
                        cidades: this.cidadesSemIbge.map((cidade)=>{
                            delete cidade.nome;
                            delete cidade.estado;
                            return cidade;
                        }),
                    },
                }).then((result) => {
                    if(this.agenciasSemNumero.length > 0){
                        this.step = 2;
                    }else{
                        this.step = 3;
                    }
                }).finally(() => {
                    this.loading = false;
                })
            },
            atualizarAgencias(){
                if(!this.$refs.form2.validate()){
                    this.$dialog.warning({
                        title: 'Atenção',
                        text: 'Preencha todas as informações para poder continuar',
                        actions: {
                            ok:'ok'
                        }
                    })
                    return;
                }
                this.loading = true;
                this.$apollo.mutate({
                    mutation: gql`mutation ($agencias:[AgenciaInput]!){
                        atualizaAgencias(agencias: $agencias){
                            id
                        }
                    }`,
                    variables: {
                        agencias: this.agenciasSemNumero.map((agencia)=>{
                            delete agencia.nome;
                            delete agencia.codigo;
                            return agencia;
                        }),
                    },
                }).then((result) => {
                    this.step = 3;
                    //this.getServicosPorLote(this.loteId);
                }).finally(() => {
                    this.loading = false;
                })
            },
            totalFiscalizacoes(fiscalizacoes) {
                const total = fiscalizacoes.length;
                let label = ' fiscalização';
                if(total > 1){
                    label = ' fiscalizações';
                }
                return total + label;
            },
            viewServico(servico, index){
                if(servico.emissaoStatus === 3){
                    this.$refs.notaFiscalErrorDialog.openDialog(servico.errors);
                    return;
                }
                this.$refs.servicoDialog.openDialog(servico, index, true, false);
            },
            async iniciarEnvio(){
                this.enviando = true;
                for(let i = 0; i < this.servicos.length && this.enviando; i++){
                    await this.emitir(this.servicos[i]);
                }
            },
            emitir(servico){
                //servico.emissaoStatus = 1;
                /*return new Promise(((resolve, reject) => {
                    setTimeout(()=>{
                        servico.emissaoStatus = 2
                        this.totalEnviado++;
                        resolve()
                    }, Math.random() * 100)
                }))*/
                return new Promise((resolve, reject) => {
                    this.$apollo.mutate({
                        mutation: NotaFiscalQueries.enviarNotaFiscal,
                        variables: {
                            id: servico.id,
                        },
                    }).then((result) => {
                        servico.emissaoStatus = 2
                        servico.notaFiscal = result.data.emitirNotaFiscal;
                        this.totalServicos--;
                        resolve();
                    }).catch(error => {
                        this.hasErrors = true;
                        servico.emissaoStatus = 3;
                        if(Object.keys(error.graphQLErrors[0]).length === 1) {
                            servico.errors = this.parseNfseErrors(error.graphQLErrors[0].message);
                        }
                        resolve();
                        //reject()
                    }).finally(()=>{
                        this.totalEnviado++;
                    })
                });
            },
            parseNfseErrors(errorString){
                return JSON.parse(errorString)
            },
            emitirLote(loteId){
                this.$apollo.mutate({
                    mutation: gql`mutation ($loteId: Int!){
                        iniciaTarefaEmiteNotasFiscais(loteId: $loteId){
                            id
                        }
                    }`,
                    variables: {
                        loteId,
                    },
                })
                .finally(()=>{
                    this.closeDialog();
                })
            }
        }

    }
</script>

<style >
    .cobrar-servicos-dialog .v-card .v-card__text{
        overflow-y: hidden;
        padding-bottom: 0;
    }

    .custom-container{
        display: flex;
        flex-direction: column;
        max-height: 100%;
        max-width: 100%;
    }

    .custom-container .body{
        overflow-y: auto;
    }

    .custom-table td{
        height: 30px;
    }
    .resposta-input{
        margin-top: 2px !important;
        margin-bottom: 2px !important;
    }
    .resposta-input .v-input__control .v-input__slot {
        min-height: auto !important;
        display: flex !important;
        align-items: center !important;
    }
</style>